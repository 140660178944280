/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React Elements
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import NavBar from "layouts/component/navbar";
import Footer from "layouts/component/footer";
import DefaultProjectCard from "examples/Cards/ProjectCards/DefaultProjectCard";
import { AUX_URL, CONNECTORS } from "utils/endpoints";
import { PageLoader } from "utils/loader";
import { getAsync } from "utils/http-client";
import { wrapSentence, toTitleCase } from "utils/strings";
import PageLayout from "examples/LayoutContainers/PageLayout";
import MDInput from "components/MDInput";
import MDAvatar from "components/MDAvatar";
import selectconnector from "utils/svgicons/select-connectors.svg";
import importtemplates from "utils/svgicons/import-templates.svg";
import getconnectors from "utils/svgicons/get-connectors.svg";
import { isAuthenticated } from "utils/auth";
import { redirectToLogin } from "utils/constants";

function Connectors() {
  const { search } = useLocation();
  const [authenticated, setAuthenticated] = useState(isAuthenticated());
  const keyword = new URLSearchParams(search).get("keyword");
  const [loading, setLoading] = useState(true);
  const [connectors, setConnectors] = useState([]);
  const [fixedData, setFixedConnectors] = useState([]);
  const isBreakpointMatch = useMediaQuery("(max-width: 600px) ");

  const handleShowAllConnectors = () => {
    window.location.href = "/connectors";
  };

  const infoCard = [
    {
      id: "1",
      title: "Select Connectors",
      backgroundImage:
        "https://igniteconnexpublicsa.blob.core.windows.net/public/projects/igcnx/exchange/assets/img/InfoCard1.svg",
      icon: selectconnector,
      description:
        "IgniteConnex provides connectors for Financial Services, Energy and Utilities Services, Salesforce, Netsuite, and a number of API and SQL based systems & application.",
    },
    {
      id: "2",
      title: "Import Templates",
      backgroundImage:
        "https://igniteconnexpublicsa.blob.core.windows.net/public/projects/igcnx/exchange/assets/img/InfoCard2.svg",
      icon: importtemplates,
      description:
        "Now automate your back-office tasks, run and manage automated workflows using our IgniteConnex Platform's ready to use tools.",
    },
    {
      id: "3",
      title: "Get Connected",
      backgroundImage:
        "https://igniteconnexpublicsa.blob.core.windows.net/public/projects/igcnx/exchange/assets/img/InfoCard3.svg",
      icon: getconnectors,
      description:
        "If you are looking for an easy, drag, and drop tool that is affordable, Ignite is your solution. Ignite is an integration tool, built in easily integrate your end enterprise systems together.",
    },
  ];

  const handleSort = (data) => data.sort((a, b) => b.is_featured - a.is_featured);

  const getConnectors = async () => {
    setLoading(true);
    let url = `${AUX_URL}/${CONNECTORS}`;
    if (keyword) {
      url = `${url}?keyword=${keyword.toLowerCase()}`;
    }
    const response = await getAsync(url);

    if (response) {
      const sortedData = handleSort(response.data);
      setLoading(false);
      setFixedConnectors(sortedData);
      setConnectors(sortedData);
    } else {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    const filteredConnectors = searchQuery
      ? fixedData.filter(
          (connector) =>
            connector.title.toLowerCase().includes(searchQuery) ||
            connector.keywords.some((kword) => kword.toLowerCase().includes(searchQuery))
        )
      : fixedData;

    setConnectors(filteredConnectors);
  };

  useEffect(async () => {
    setAuthenticated(isAuthenticated());
    if (authenticated) {
      getConnectors();
    }
  }, []);

  if (!authenticated) {
    redirectToLogin();
  }
  // test
  return (
    <PageLayout>
      <NavBar />
      {loading ? <PageLoader /> : null}
      <MDBox>
        <MDBox
          marginBottom={isBreakpointMatch ? "2.5rem" : "10rem"}
          sx={{
            backgroundColor: "#231F20",
          }}
        >
          <Grid container mt={7}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <MDTypography
                variant="h1"
                color="white"
                display="flex"
                fontSize="48px"
                justifyContent="center"
                fontWeight="bold"
                sx={{ textAlign: "center" }}
                mb={1.25}
                mt={3.75}
              >
                IgniteConnex Exchange
              </MDTypography>
              <MDTypography
                variant="h4"
                color="white"
                fontWeight="regular"
                sx={{ textAlign: "center" }}
              >
                Connect systems by building automated flows using drag and drop!
              </MDTypography>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={1}
            position={isBreakpointMatch ? "" : "relative"}
            paddingTop={isBreakpointMatch ? "2.5rem" : ""}
            paddingBottom={isBreakpointMatch ? "3rem" : ""}
            sx={{
              top: "5rem",
              pl: { xs: "1rem", sm: "", md: "2.25rem", lg: "3rem", xl: "6.25rem" },
              pr: { xs: "1rem" },
            }}
          >
            {infoCard.map((info) => (
              <Grid
                item
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                key={info.id}
                sx={{ px: { xl: "100px", md: "50px" } }}
              >
                <Card
                  sx={{
                    backgroundImage: `url(${info.backgroundImage})`,
                    backgroundPosition: "right center",
                    backgroundRepeat: "no-repeat",
                    display: "flex",
                    height: "100%",
                    width: { sm: "102%" },
                    flexDirection: "column",
                  }}
                >
                  <MDBox sx={{ px: "20px", py: "24px" }}>
                    <MDAvatar src={info.icon} />

                    <MDTypography
                      variant="h4"
                      fontWeight="medium"
                      textTransform="capitalize"
                      mb={1}
                    >
                      {info.title}
                    </MDTypography>
                    <MDTypography variant="subtitle2" color="text" fontWeight="regular">
                      {info.description}
                    </MDTypography>
                  </MDBox>
                </Card>
              </Grid>
            ))}
          </Grid>
        </MDBox>

        <MDBox mr={3} ml={3}>
          {connectors && (
            <Grid container spacing={3}>
              <Grid item xs={12} md={8} xl={8}>
                <MDBox marginLeft="10px">
                  <MDTypography variant="h4" fontWeight="bold" color="dark">
                    Connectors
                  </MDTypography>
                  <MDBox display="flex">
                    <MDTypography fontWeight="light" color="dark">
                      {keyword
                        ? `Connectors related to the ${toTitleCase(keyword)} category!`
                        : "Connect systems by building automated flows using drag and drop!"}
                    </MDTypography>
                    {keyword ? (
                      <MDBox>
                        <Button
                          sx={{ borderRadius: "20px", borderColor: "#22C5DB", color: "#22C5DB" }}
                          component="a"
                          target="_blank"
                          rel="noreferrer"
                          variant="outlined"
                          size="small"
                          color="info"
                          style={{ marginLeft: "10px" }}
                        >
                          <MDTypography
                            variant="body2"
                            fontWeight="bold"
                            color="#22C5DB"
                            onClick={() => handleShowAllConnectors()}
                          >
                            Show all connectors
                          </MDTypography>
                        </Button>
                      </MDBox>
                    ) : null}
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid item xs={12} md={4} xl={4}>
                <MDBox>
                  <MDInput
                    fullWidth
                    placeholder="Search by Connector Name"
                    onChange={(e) => handleSearch(e)}
                  />
                </MDBox>
              </Grid>
            </Grid>
          )}
          <Grid container sx={{ gridTemplateColumn: "auto" }}>
            {connectors.length > 0 ? (
              <>
                {connectors.map((connector, index) => (
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={4}
                    display="grid"
                    sx={{
                      marginTop: index >= 0 ? "2rem" : 0,
                    }}
                  >
                    <DefaultProjectCard
                      image={connector.icon_url}
                      title={connector.title}
                      description={wrapSentence(connector.description)}
                      featured={connector.is_featured}
                      connectorActions={connector.keywords.map(
                        (distinctkeyword) => distinctkeyword
                      )}
                      action={{
                        type: "internal",
                        route: `${connector.name}`,
                        color: "info",
                        label: "View connector",
                      }}
                    />
                  </Grid>
                ))}
              </>
            ) : (
              <>
                <Grid container spacing={3} sx={{ height: "300px" }}>
                  <Grid item xs={12} md={12} xl={12}>
                    <MDBox marginTop="100px" sx={{ textAlign: "center" }}>
                      <MDTypography variant="h5" fontWeight="medium">
                        No Connectors Found
                      </MDTypography>
                    </MDBox>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </PageLayout>
  );
}

export default Connectors;
