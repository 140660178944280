import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import Card from "@mui/material/Card";
import { Grid, useMediaQuery } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { AUX_URL, CONNECTORS } from "utils/endpoints";
import { PageLoader } from "utils/loader";
import { getAsync } from "utils/http-client";
import { isAuthenticated } from "utils/auth";
import { wrapSentence } from "utils/strings";
import NavBar from "layouts/component/navbar";
import Footer from "layouts/component/footer";

import PageLayout from "examples/LayoutContainers/PageLayout";
import InfoCard from "layouts/views/InfoCard";
import ConnectorActionCard from "layouts/views/ConnectorActionCard";
import MDInput from "components/MDInput";
import about from "utils/svgicons/about.svg";
import usecases from "utils/svgicons/use-cases.svg";
import additionalfeatures from "utils/svgicons/additional-features.svg";

function Connector() {
  const { name } = useParams();
  const [loading, setLoading] = useState(true);
  const [information, setConnectorData] = useState();
  const [fixedData, setFixedConnectors] = useState();
  const [statusCode, setStatusCode] = useState(null);
  const [authenticated, setAuthenticated] = useState(false);

  const checkAuth = async () => {
    const auth = await isAuthenticated();
    setAuthenticated(auth);
  };

  const isBreakpointMatch = useMediaQuery("(max-width: 865px) ");

  const redirectToHomePage = () => {
    window.location.href = "/connectors";
  };

  const getConnectors = async () => {
    try {
      const response = await getAsync(`${AUX_URL}/${CONNECTORS}/${name}/actions?name=true`);
      setStatusCode(response.status);
      if (response.status === 200) {
        setFixedConnectors(response.data);
        setConnectorData(response.data);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setConnectorData({
      ...information,
      actions: fixedData.actions.filter(({ action }) =>
        action.toLowerCase().includes(e.target.value.toLowerCase())
      ),
    });
  };

  useEffect(async () => {
    checkAuth();
    getConnectors();
  }, []);

  if (statusCode !== null && statusCode === 400) {
    redirectToHomePage();
  }

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const keywords = information?.connector.keywords
    .split(",")
    .map((keyword) => capitalizeFirstLetter(keyword.trim()));
  return (
    <PageLayout>
      <NavBar />
      {loading ? <PageLoader /> : null}
      {information ? (
        <MDBox>
          <MDBox
            mt={10}
            marginBottom={
              isBreakpointMatch ||
              (information.connector.about === null &&
                information.connector.additional_features === null &&
                information.connector.use_cases === null)
                ? "2.5rem"
                : "14.75rem"
            }
            sx={{
              backgroundColor: "#231F20",
            }}
          >
            <Grid container spacing={5} py={3} px={5}>
              <Grid item xs={12} md={2} xl={2}>
                <Card>
                  <MDBox p={1} sx={{ textAlign: "center" }}>
                    <MDBox
                      component="img"
                      src={information.connector.icon_url}
                      alt="Brand"
                      width="8rem"
                    />
                  </MDBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={10} xl={10}>
                <MDBox>
                  <MDTypography fontSize="2rem" color="white">
                    {information.connector.title}
                  </MDTypography>
                  <MDTypography color="white" fontWeight="regular" fontSize="18px">
                    {information.connector.description}
                  </MDTypography>
                  <MDBox mt={2} display="-webkit-box" flexWrap="wrap" marginLeft="-5px">
                    {keywords.map((item) => (
                      <Card key={uuidv4()} sx={{ margin: 1, backgroundColor: "#F6F6F6" }}>
                        <MDTypography variant="body2" fontWeight="regular" py={0.75} px={2}>
                          {item}
                        </MDTypography>
                      </Card>
                    ))}
                  </MDBox>
                </MDBox>
              </Grid>
            </Grid>
            <MDBox
              position={isBreakpointMatch ? "static" : "relative"}
              top={isBreakpointMatch ? " " : "150px"}
            >
              <Grid container spacing={3} justifyContent="center" px={5}>
                {information.connector.about !== null && (
                  <InfoCard
                    icon={about}
                    title="About"
                    description={wrapSentence(information.connector.about, 150)}
                  />
                )}
                {information.connector.additional_features !== null && (
                  <InfoCard
                    icon={additionalfeatures}
                    title="Additional Features"
                    description={wrapSentence(information.connector.additional_features, 150)}
                  />
                )}
                {information.connector.use_cases !== null && (
                  <InfoCard
                    icon={usecases}
                    title="Use Cases"
                    description={wrapSentence(information.connector.use_cases, 150)}
                  />
                )}
              </Grid>
            </MDBox>
          </MDBox>

          <Grid container spacing={3} p={6}>
            <Grid item xs={12} md={12} xl={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} xl={8}>
                  <MDBox>
                    <MDTypography variant="h3" color="dark">
                      Connector Actions
                    </MDTypography>
                    {information.actions.length > 0 ? (
                      <>
                        <MDTypography fontSize="16px" color="dark" fontWeight="regular">
                          {information.connector.title} connector supports the following actions:
                        </MDTypography>
                      </>
                    ) : (
                      <MDTypography fontSize="16px" color="dark" fontWeight="regular">
                        No Connector Action Available
                      </MDTypography>
                    )}
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={4} xl={4}>
                  <MDBox>
                    <MDInput
                      placeholder="Search by Connector Action Name"
                      fullWidth
                      onChange={(e) => handleSearch(e)}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={12} md={12} xl={12}>
                  <Grid container spacing={3}>
                    {information.actions.map((entity) => (
                      <Grid item xs={12} md={6} xl={6} key={entity.id}>
                        <MDBox>
                          <ConnectorActionCard
                            icon="electrical_services"
                            id={entity.id}
                            title={entity.action}
                            authenticated={authenticated}
                            description={
                              entity.description
                                ? entity.description
                                : `Using this connector you can ${entity.action.toLowerCase()}`
                            }
                          />
                        </MDBox>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Footer />
        </MDBox>
      ) : null}
    </PageLayout>
  );
}

export default Connector;
