// Saves an item to local storage
export const setLocalItem = (key, value) => localStorage.setItem(key, value);

// retrives an item from local storage
export const getLocalItem = (key) => localStorage.getItem(key);

// Removes an item from local storage
export const removeLocalItem = (key) => localStorage.removeItem(key);

// Saves an item to session storage
export const setSessionItem = (key, data) => sessionStorage.setItem(key, data);

// retrives an item from session storage
export const getSessionItem = (key) => sessionStorage.getItem(key);

// Removes an item from session storage
export const removeSessionItem = (key) => sessionStorage.removeItem(key);
