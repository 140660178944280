/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDAvatar from "components/MDAvatar";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// import Footer from "examples/Footer";

function InfoCard({ icon, title, description }) {
  return (
    <Grid item xs={12} md={4} xl={4} minHeight="20rem" maxHeight="25rem">
      <Card sx={{ height: "100%", borderBottom: "5px solid #F46224" }}>
        <MDBox p={2}>
          <MDBox p={2}>
            <MDBox mb={1} display="grid">
              <MDAvatar src={icon} sx={{ width: 56, height: 56 }} />
            </MDBox>
          </MDBox>
          <MDBox pb={2} px={2} textAlign="left" lineHeight={1.25}>
            <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              {title}
            </MDTypography>
            <MDTypography variant="subtitle2" color="text" fontWeight="regular">
              {description}
            </MDTypography>
          </MDBox>
        </MDBox>
      </Card>
    </Grid>
  );
}

InfoCard.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default InfoCard;
