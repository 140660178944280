import { customCall } from "./http-client";

import { getLocalItem, setLocalItem, removeLocalItem } from "./storage";
import {
  EXCHANGE_ACCESS_TOKEN,
  EXCHANGE_ACCESS_TOKEN_PUBLIC_KEY,
  CURRENT_PAGE_URL,
  SSO_LOGOUT_URL,
} from "./constants";
import { AUX_URL, KEYCLOAK_LOGOUT_URL } from "./endpoints";

// Delete user session items, and redirects to login page
export const signout = async () => {
  removeLocalItem(EXCHANGE_ACCESS_TOKEN);
  removeLocalItem(EXCHANGE_ACCESS_TOKEN_PUBLIC_KEY);
  if (CURRENT_PAGE_URL) {
    window.location.href = CURRENT_PAGE_URL;
  } else {
    window.location.href = SSO_LOGOUT_URL;
  }
};

// Checks whether the user has an access token or not
export const isAuthenticated = async () => {
  let authenticated = false;
  const token = getLocalItem(EXCHANGE_ACCESS_TOKEN);
  if (token) {
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const response = await customCall(`${AUX_URL}/igcnx/exchange/sso/validate`, headers);
    if (response && response.status === 200) {
      authenticated = true;
    } else {
      signout();
    }
  }
  return authenticated;
};

export const handleOAuth = async (response) => {
  if (response && response.data && response.data.length) {
    setLocalItem(EXCHANGE_ACCESS_TOKEN, response.data[0].value.token);
    setLocalItem(SSO_LOGOUT_URL, KEYCLOAK_LOGOUT_URL);
  }
  return isAuthenticated();
};
