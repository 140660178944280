/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// import { makeStyles } from "@material-ui/core";
// import CardMedia from "@mui/material/CardMedia";
// import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import { Icon, Typography, Grid, Button } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import { HOST_URL } from "utils/endpoints";

function DefaultProjectCard({ image, title, description, action, featured, connectorActions }) {
  const handleKeywordSearch = (actionName) => {
    window.location.href = `${HOST_URL}?keyword=${actionName.trim()}`;
  };
  const renderConnectorActions = connectorActions;
  //   const renderConnectorActions =
  //   connectorActions.length > 3 ? connectorActions.slice(0, 3) : connectorActions;
  // if (connectorActions.length > 3)
  //   renderConnectorActions.push(`+${connectorActions.length - 3} More`);
  return (
    <Card
      sx={{
        padding: 1.5,
        margin: 2,
        backgroundColor: "transparent",
        borderTop: featured ? "5px solid #F46224" : "none",
        boxShadow: "0px 1px 5px 0px #00000026",
      }}
    >
      {featured ? (
        <MDBox
          display="flex"
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          padding="5px 8px"
          gap="8px"
          marginTop="-4px"
          position="absolute"
          width="90px"
          height="30px"
          right="0px"
          top="0px"
          textAlign="center"
          sx={{ backgroundColor: " #F46224", borderRadius: "0px 4px" }}
        >
          <Typography
            font-family="Roboto"
            font-style="normal"
            font-weight="500"
            font-size="1px"
            line-height="15px"
            text-align="right"
            text-transform="uppercase"
            color="#000000"
            variant="h6"
          >
            FEATURED
          </Typography>
        </MDBox>
      ) : null}

      <Grid container display="flex" justifyContent="left">
        <Grid>
          <MDBox>
            <Card>
              <MDAvatar src={image} name={title} size="xl" variant="rounded" />
            </Card>
          </MDBox>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item marginTop={2}>
          {action.type === "internal" ? (
            <MDTypography
              component={Link}
              to={action.route}
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          ) : (
            <MDTypography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
            >
              {title}
            </MDTypography>
          )}
        </Grid>
        {/* <MDBox mb={3} lineHeight={0}> */}
        <Grid
          item
          lineHeight={0}
          xs={12}
          md={12}
          lg={12}
          sx={{ minHeight: { md: "84px", lg: "100px" } }}
        >
          <MDTypography variant="button" fontWeight="light" color="text">
            {description}
          </MDTypography>
        </Grid>
      </Grid>

      <Grid container sx={{ minHeight: { md: "150px", lg: "10px" } }}>
        {renderConnectorActions.map((actionName) => (
          <Grid item>
            <Card
              onClick={() => handleKeywordSearch(actionName)}
              sx={{
                backgroundColor: actionName.includes("+") ? "#FFFFFF" : "#F6F6F6",
                margin: 1,
                textAlign: "center",
                border: actionName.includes("+") ? "1px solid #F46224" : "none",
                cursor: "pointer",
              }}
            >
              <MDBox p={1}>
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  sx={{ color: actionName.includes("+") ? "#F46224" : "#000000" }}
                >
                  {actionName.toUpperCase()}
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        ))}
        {/* </MDBox> */}
      </Grid>
      <Grid container height="100%">
        <Grid
          item
          style={{ marginTop: "auto", marginBottom: "1.25rem" }}
          xs={12}
          md={12}
          lg={12}
          xl={12}
        >
          <MDBox pt={4}>
            {action.type === "internal" ? (
              <Button
                href={action.route}
                target="_blank"
                rel="noreferrer"
                sx={{ borderRadius: "20px", borderColor: "#22C5DB", color: "#22C5DB" }}
                component={Link}
                to={action.route}
                variant="outlined"
                size="small"
                color={action.color}
                style={{ margin: "auto" }}
                fullWidth
              >
                <MDTypography variant="body2" fontWeight="bold" color="#22C5DB">
                  {action.label} <Icon>open_in_new_tab</Icon>
                </MDTypography>
              </Button>
            ) : (
              <Button
                sx={{ backgroundColor: "black", borderRadius: "20px" }}
                component="a"
                href={action.route}
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                color={action.color}
                style={{ marginLeft: "auto" }}
                fullWidth
              >
                <MDTypography variant="body2" fontWeight="bold" color="#22C5DB">
                  {action.label} <Icon>open_in_new_tab</Icon>
                </MDTypography>
              </Button>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  connectorActions: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.shape({
    type: PropTypes.oneOf(["external", "internal"]),
    route: PropTypes.string.isRequired,
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
      "white",
    ]).isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  featured: PropTypes.bool.isRequired,
  connectorActions: PropTypes.arrayOf(PropTypes.string),
};

export default DefaultProjectCard;
