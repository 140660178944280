// React Elements
import { useEffect } from "react";
import { isAuthenticated } from "utils/auth";
import { getRandomState } from "utils/constants";
import { KEYCLOAK_IDP_AUTH_URL } from "utils/endpoints";

function Login() {
  const authenticated = isAuthenticated();
  function loginWithKeycloak() {
    const AUTH_URL = `${KEYCLOAK_IDP_AUTH_URL}&state=${getRandomState()}}`;
    window.open(AUTH_URL, "_self");
  }

  useEffect(async () => {
    loginWithKeycloak();
  }, []);

  if (!authenticated) {
    window.location.href = "/connectors";
  }
  return null;
}

export default Login;
