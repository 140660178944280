// latest:

// @mui material components
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import MDAvatar from "components/MDAvatar";
import Icon from "@mui/material/Icon";
import { useParams } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import NavBar from "layouts/component/navbar";
import Footer from "layouts/component/footer";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { PageLoader } from "utils/loader";
import { getAsync } from "utils/http-client";
import { AUX_URL, CONNECTORS, pathname } from "utils/endpoints";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";
import { setSessionItem } from "utils/storage";
import { redirectToLogin, CURRENT_PAGE_URL } from "utils/constants";
import { isAuthenticated } from "utils/auth";
import "../../components/scss/index.css";
import Markdown from "layouts/component/markdown";

function ConnectorAction() {
  const { name, action } = useParams();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);
  const [json, setJSON] = useState();
  const [information, setInformation] = useState();
  const [copyToolTipText, setCopyToolTipText] = useState("Copy To Clipboard");
  const [downloadToolTipText, setDownloadToolTipText] = useState("Download");

  const checkAuth = async () => {
    const auth = await isAuthenticated();
    setAuthenticated(auth);
  };

  const getConnectorActions = async () => {
    try {
      const response = await getAsync(
        `${AUX_URL}/${CONNECTORS}/${name}/actions/${action}?name=true`
      );
      if (response.status === 200) {
        setLoading(false);
        const responseData = response.data;
        setInformation(responseData);
        if (responseData.action.flow_json) setJSON(responseData.action.flow_json);
      }
    } finally {
      setLoading(false);
    }
  };

  const resetDefaults = () => {
    setCopyToolTipText("Copy to Clipboard");
    setDownloadToolTipText("Download");
  };

  const handleButtonClick = async (ButtonAction) => {
    console.log("happy");
    if (!authenticated) {
      setSessionItem(CURRENT_PAGE_URL, pathname);
      redirectToLogin();
      return;
    }

    if (!json) {
      if (ButtonAction === "copy") {
        setCopyToolTipText("Failed to Copy");
      } else {
        setDownloadToolTipText("Failed to Download");
      }
      return;
    }

    if (ButtonAction === "copy") {
      navigator.clipboard.writeText(JSON.stringify(json));
      setCopyToolTipText("Copied");
    } else {
      const element = document.createElement("a");
      const file = new Blob([JSON.stringify(json)], { type: "text/plain" });
      element.href = URL.createObjectURL(file);
      element.download = `${name}-${action.replaceAll(" ", "-").toLowerCase()}.json`;
      document.body.appendChild(element);
      element.click();
      setDownloadToolTipText("Downloaded");
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await checkAuth();
      await getConnectorActions();
    };

    fetchData();
  }, []);

  return (
    <>
      <PageLayout>
        <NavBar />
        {loading ? <PageLoader /> : null}
        {information ? (
          <MDBox marginTop="5rem">
            <Grid container xs={12} md={12} xl={12} pl={10} pr={10}>
              <Grid item xs={12} md={6} xl={6}>
                <MDBox display="flex">
                  <MDBox p={2} pt={1}>
                    <MDAvatar src={information.connector.icon_url} />
                  </MDBox>
                  <MDBox>
                    <MDTypography variant="h6" color="dark">
                      {information.connector.title}
                    </MDTypography>
                    <MDTypography variant="subtitle1" color="dark">
                      {information.action.action}
                    </MDTypography>
                  </MDBox>
                </MDBox>
              </Grid>
              <Grid
                item
                gap={2}
                xs={12}
                sm={6}
                md={6}
                xl={6}
                padding="5px"
                className="btn-mob-view"
              >
                <Tooltip
                  title={authenticated ? copyToolTipText : "Login to Copy Code"}
                  placement="top"
                >
                  <Button
                    sx={{
                      borderRadius: "8px",
                      borderColor: "#22C5DB",
                      color: "#22C5DB",
                      marginLeft: "auto",
                      cursor: "pointer",
                    }}
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    size="small"
                    style={{ marginLeft: "auto" }}
                    onClick={() => handleButtonClick("copy")}
                    onMouseLeave={resetDefaults}
                  >
                    <MDTypography variant="body2" fontWeight="bold" sx={{ color: "#22C5DB" }}>
                      Copy to Clipboard <Icon>{authenticated ? "copy" : "lock"}</Icon>
                    </MDTypography>
                  </Button>
                </Tooltip>
                <Tooltip
                  title={authenticated ? downloadToolTipText : "Login to Download Code"}
                  placement="top"
                >
                  <Button
                    sx={{
                      borderRadius: "8px",
                      borderColor: "#22C5DB",
                      color: "#22C5DB",
                      cursor: "pointer",
                    }}
                    component="a"
                    target="_blank"
                    rel="noreferrer"
                    variant="outlined"
                    size="small"
                    onClick={() => {
                      handleButtonClick("download");
                    }}
                    onMouseLeave={() => resetDefaults()}
                  >
                    <MDTypography variant="body2" fontWeight="bold" sx={{ color: "#22C5DB" }}>
                      Download <Icon>{authenticated ? "download" : "lock"}</Icon>
                    </MDTypography>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid sm={6} md={10} lg={12} xl={12} p={4} pt={0} className="mob-view-grid">
              <MDBox
                width="80%"
                ml="auto"
                mr="auto"
                boxShadow="0 4px 8px rgba(0, 0, 0, 0.1)"
                padding="10px"
                backgroundColor="#fff"
                textAlign="left"
                className="mob-view-box"
              >
                {Markdown(information.action.flow_json[0].info)}
              </MDBox>
            </Grid>
            <Footer />
          </MDBox>
        ) : null}
      </PageLayout>
    </>
  );
}

export default ConnectorAction;
