/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// import { styled } from "@mui/material/styles";
// import Button from "@mui/material/Button";
// @mui material components
import { Card } from "@mui/material";
// import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import JHA from "assets/igcnx/JHA.svg";
import AICPA from "assets/igcnx/AICPA SOC blue logo.png";
import linkedin from "assets/igcnx/linkedin.svg";
import youtube from "assets/igcnx/youtube.svg";

function Footer() {
  return (
    <Card
      sx={{
        bottom: 0,
        zIndex: 1000,
        borderRadius: 0,
        backgroundColor: "#231F20",
        backgroundRepeat: "no-repeat",
        backgroundSize: { lg: "18%", xl: "19%" },
        backgroundPosition: "calc(82.5%) bottom",
        "@media (max-width: 600px)": {
          backgroundSize: "55%",
          backgroundPosition: "calc(125% - 0.25px) calc(100% - 90px)",
        },
        "@media (max-width: 280px)": {
          backgroundSize: "60%",
          backgroundPosition: "calc(125% - 0.25px) calc(100% - 140px)",
        },
        "@media (max-width: 850px)": {
          backgroundSize: "40%",
          backgroundPosition: "calc(125% - 0.25px) calc(100% - 140px)",
        },
      }}
    >
      <Grid container display="flex" mt={3.75} ml={5}>
        <Grid item xs={12} md={4} lg={4}>
          <MDBox>
            <img
              component="img"
              height="70px"
              width="120px"
              src="https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-color-tall-white-light.svg"
              alt="Brand"
            />
            <MDTypography variant="h6" color="white">
              Ignite the future of your bank with data and application integration
            </MDTypography>
          </MDBox>
          <MDBox display="flex" height="fit-content" mt={2}>
            <Link href="https://www.linkedin.com/company/cginfinity" target="_blank">
              <img height="60px" component="img" src={linkedin} alt="Brand" />
            </Link>
            <Link href="https://youtube.com/cginfinityinc" ml={2} mt={2} target="_blank">
              <img height="30px" component="img" src={youtube} alt="Brand" />
            </Link>
          </MDBox>
          <MDBox width="fit-content" mt={2.25} mb={3.75}>
            <MDTypography variant="button" fontWeight="regular" color="white">
              © {new Date().getFullYear()} IgniteConnex. All rights reserved
            </MDTypography>
          </MDBox>
        </Grid>
        <Grid
          item
          margin={1}
          gap={1}
          xs={9}
          md={9}
          lg={7}
          display="flex"
          justifyContent="space-around"
        >
          <Grid item>
            <MDBox width="fit-content">
              <MDTypography
                sx={{ fontFamily: "Roboto" }}
                variant="h6"
                component="div"
                color="white"
              >
                EXPLORE
              </MDTypography>
              <Link href="https://www.igniteconnex.com/why-igniteconnex/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Why IgniteConnex?
                </MDTypography>
              </Link>
              <br />
              <Link href="https://www.igniteconnex.com/products/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Products
                </MDTypography>
              </Link>
              <br />
              <Link href="https://www.igniteconnex.com/tech-corner/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Tech Corner
                </MDTypography>
              </Link>
              <br />
              <Link href="https://www.igniteconnex.com/company/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Company
                </MDTypography>
              </Link>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox width="fit-content" display="grid">
              <MDTypography
                sx={{ fontFamily: "Roboto" }}
                variant="h6"
                component="div"
                color="white"
              >
                RESOURCES
              </MDTypography>

              <Link href="https://www.igniteconnex.com/blog/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Blog
                </MDTypography>
              </Link>

              <Link href="https://www.igniteconnex.com/blog/#faqs" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  FAQs
                </MDTypography>
              </Link>
              <Link
                href="https://www.igniteconnex.com/master-subscription-agreement/"
                target="_blank"
              >
                <MDTypography variant="button" fontWeight="regular" color="white">
                  MSA
                </MDTypography>
              </Link>

              <Link href="https://www.igniteconnex.com/terms-and-conditions/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Terms & Conditions
                </MDTypography>
              </Link>

              <Link href="https://www.igniteconnex.com/sandbox-end-user-license/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Sandbox EULA
                </MDTypography>
              </Link>

              <Link href="https://www.igniteconnex.com/privacy-policy-latest/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Privacy Policy
                </MDTypography>
              </Link>
            </MDBox>
          </Grid>
          <Grid item>
            <MDBox width="fit-content">
              <MDTypography
                sx={{ fontFamily: "Roboto" }}
                variant="h6"
                component="div"
                color="white"
              >
                TOOLS
              </MDTypography>
              <Link href="https://www.igniteconnex.com/contact-us/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Contact
                </MDTypography>
              </Link>
              <br />
              <Link href="https://trust.igniteconnex.com/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Trust Center
                </MDTypography>
              </Link>
              <br />
              <Link href="https://status.igniteconnex.com/" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Status Center
                </MDTypography>
              </Link>
              <br />
              <Link href="https://desk.igniteconnex.com/support/home" target="_blank">
                <MDTypography variant="button" fontWeight="regular" color="white">
                  Help Desk
                </MDTypography>
              </Link>
            </MDBox>
          </Grid>
          <Grid item>
            <img src={AICPA} alt="AICPA" style={{ marginRight: "1rem" }} />
            <br />
            <img src={JHA} alt="AICPA" style={{ marginRight: "1rem" }} />
            <br />
            <MDBox mt={5}>
              <img
                component="img"
                height="50px"
                width="100px"
                src="https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-color-tall-white-light.svg"
                alt="Brand"
              />
            </MDBox>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
}

Footer.defaultProps = {};

Footer.propTypes = {};

export default Footer;
