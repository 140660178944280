import axios from "axios";
import { getLocalItem } from "./storage";
import { AUX_URL, PROXY_ENDPOINT } from "./endpoints";
import { EXCHANGE_ACCESS_TOKEN } from "./constants";

export const getHeaders = async () => {
  const headers = {
    "Access-Control-Allow-Origin": "*",
  };
  const token = await getLocalItem(EXCHANGE_ACCESS_TOKEN);
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return headers;
};

// Generic Method to make a GET API request
export const getAsync = async (url) => {
  try {
    return await axios(url, {
      method: "GET",
      headers: await getHeaders(),
    });
  } catch (err) {
    return err.response || err.request;
  }
};

// Generic Method to make a POST API request
export const postAsync = async (url, data) => {
  try {
    return await axios(url, {
      method: "POST",
      headers: await getHeaders(),
      data,
    });
  } catch (err) {
    return err.response || err.request;
  }
};

// Generic Method to make a PUT API request
export const putAsync = async (url, data) => {
  try {
    return await axios(url, {
      method: "PUT",
      headers: await getHeaders(),
      data,
    });
  } catch (err) {
    return err.response || err.request;
  }
};

// Generic Method to make a PUT API request
export const patchAsync = async (url, data) => {
  try {
    return await axios(url, {
      method: "PATCH",
      headers: await getHeaders(),
      data,
    });
  } catch (err) {
    return err.response || err.request;
  }
};

// Generic Method to make a DELETE API request
export const deleteAsync = async (url) => {
  try {
    return await axios(url, {
      method: "DELETE",
      headers: await getHeaders(),
    });
  } catch (err) {
    return err.response || err.request;
  }
};

// Generic Method to make a custom HTTP call
export const customCall = async (url, headers, method, data) => {
  try {
    return await axios(url, {
      method: method || "GET",
      headers,
      data: data || {},
    });
  } catch (err) {
    return err.response || err.request;
  }
};

// Generic Method to make an HTTP call using Ignite Proxy Endpoint
export const useProxy = async (data) => {
  try {
    return await axios(`${AUX_URL}/${PROXY_ENDPOINT}`, {
      method: "POST",
      data,
    });
  } catch (err) {
    return err.response || err.request;
  }
};
