import React from "react";
import CircularProgress from "@mui/material/CircularProgress";

export const PageLoader = () => (
  <CircularProgress
    size={60}
    color="info"
    sx={{ position: "fixed", bottom: "50%", left: "50%", zIndex: "10" }}
  />
);

export const SignInPageLoader = () => (
  <CircularProgress
    size={60}
    color="info"
    sx={{ position: "fixed", bottom: "50%", left: "48%", zIndex: "10" }}
  />
);

export const ModalLoader = () => (
  <CircularProgress
    size={60}
    color="info"
    sx={{
      width: "10",
      height: "10",
      position: "absolute",
      bottom: "50%",
      left: "47%",
      zIndex: "8",
    }}
  />
);
