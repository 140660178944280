export const { protocol, host, pathname } = window.location;
const CLIENT = "exchange";

let API_BASE_URL = "https://api.igniteconnex.io";
let KC_IDP_URL = "https://sso.igniteconnex.io";
let KC_IDP_REALM_NAME = "igniteconnex";
let KC_IDP_CLIENT_ID = "exchange";
let KC_IDP_CLIENT_SCOPES = "email roles openid";
let KC_IDP_REDIRECT_URI = `https://api.igniteconnex.io/aux/igcnx/${CLIENT}/sso/login`;

if (["qaexchange.igniteconnex.io", "qa-igniteconnex-exchange.azurewebsites.net"].includes(host)) {
  // QA,Staging
  API_BASE_URL = "https://qa.igniteconnex.io";
  KC_IDP_URL = "https://qakeycloak.igniteconnex.io";
  KC_IDP_REALM_NAME = "igniteconnex";
  KC_IDP_CLIENT_ID = "exchange";
  KC_IDP_CLIENT_SCOPES = "email roles openid";
  KC_IDP_REDIRECT_URI = `https://qa.igniteconnex.io/aux/igcnx/${CLIENT}/sso/login`;
} else if (
  ["devexchange.igniteconnex.io", "dev-ignite-exchange.azurewebsites.net"].includes(host)
) {
  // Dev
  API_BASE_URL = "https://dev.igniteconnex.io";
  KC_IDP_URL = "https://devkeycloak.igniteconnex.io";
  KC_IDP_REALM_NAME = "igniteconnex";
  KC_IDP_CLIENT_ID = "exchange";
  KC_IDP_CLIENT_SCOPES = "email roles openid";
  KC_IDP_REDIRECT_URI = `https://dev.igniteconnex.io/aux/igcnx/${CLIENT}/sso/login`;
} else if (
  ["localhost:8101", "host.docker.internal:8101", "localhost", "local.igniteconnex.io"].includes(
    host
  )
) {
  // Local
  API_BASE_URL = "http://local.igniteconnex.io";
  KC_IDP_URL = "https://devkeycloak.igniteconnex.io";
  KC_IDP_REALM_NAME = "igniteconnex";
  KC_IDP_CLIENT_ID = "localexchange";
  KC_IDP_CLIENT_SCOPES = "email roles openid";
  KC_IDP_REDIRECT_URI = `http://local.igniteconnex.io/aux/igcnx/${CLIENT}/sso/login`;
}

// IDP INFO URLs
export const KEYCLOAK_IDP_URL = KC_IDP_URL;
export const KEYCLOAK_IDP_REALM_NAME = KC_IDP_REALM_NAME;
export const KEYCLOAK_IDP_CLIENT_ID = KC_IDP_CLIENT_ID;
export const KEYCLOAK_IDP_REDIRECT_URI = KC_IDP_REDIRECT_URI;
export const KEYCLOAK_IDP_CLIENT_SCOPES = KC_IDP_CLIENT_SCOPES;
export const SIGNOUT_PAGE_URL = `${protocol}//${host}${pathname}/logout`;
export const KEYCLOAK_IDP_AUTH_URL = `${KEYCLOAK_IDP_URL}/auth/realms/${KEYCLOAK_IDP_REALM_NAME}/protocol/openid-connect/auth?client_id=${KEYCLOAK_IDP_CLIENT_ID}&response_type=code&scope=${encodeURIComponent(
  KEYCLOAK_IDP_CLIENT_SCOPES
)}&redirect_uri=${encodeURIComponent(KEYCLOAK_IDP_REDIRECT_URI)}`;
export const KEYCLOAK_LOGOUT_URL = `${KEYCLOAK_IDP_URL}/auth/realms/${KEYCLOAK_IDP_REALM_NAME}/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent(
  `${SIGNOUT_PAGE_URL}`
)}&client_id=${KC_IDP_CLIENT_ID}`;

// Ignite Services API Endpoints
export const AUX_URL = `${API_BASE_URL}/aux`;
export const PROXY_ENDPOINT = `api/v1/proxy`;
export const CONNECTORS = "api/v1/connectors";
export const KEYCLOAK_SSO_TOKEN_URL = `${AUX_URL}/igcnx/${CLIENT}/sso/token`;

// UI Routes
export const EXCHANGE_CURRENT_PAGE_URL = `${protocol}//${host}${pathname}`;

// Public URLs
export const DOCS_URL = "https://docs.igniteconnex.io";
export const SUPPORT_URL = "https://desk.igniteconnex.com/";
export const IGNITECONNEX_URL = "https://www.igniteconnex.com/";

// Frontend Page Urls
export const HOST_NAME = `${host}`;
export const HOST_URL = `${protocol}//${host}${pathname}`;
