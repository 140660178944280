/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/
// @mui icons
// import Icon from "@mui/material/Icon";

// Material Dashboard 2 React layouts
import Connectors from "layouts/connectors";
import Connector from "layouts/connector";
import Login from "layouts/views/login";
import OAuth from "layouts/views/oauth";
import Logout from "layouts/views/logout";
import ConnectorAction from "layouts/connector-action";

const routes = [
  {
    type: "collapse",
    name: "Connectors",
    key: "connectors",
    route: "/connectors",
    component: <Connectors />,
  },
  {
    type: "collapse",
    name: "Connector",
    key: "Connector",
    visibility: "hidden",
    route: "/connectors/:name",
    component: <Connector />,
  },
  {
    type: "collapse",
    name: "Connector Action",
    key: "Connector Action",
    visibility: "hidden",
    route: "/connectors/:name/:action",
    component: <ConnectorAction />,
  },
  {
    type: "collapse",
    name: "Login",
    key: "Login",
    visibility: "hidden",
    route: "/login",
    component: <Login />,
  },
  {
    type: "collapse",
    name: "Logout",
    key: "Logout",
    visibility: "hidden",
    route: "/logout",
    component: <Logout />,
  },
  {
    type: "collapse",
    name: "OAuth",
    key: "OAuth",
    visibility: "hidden",
    route: "/oauth/:token",
    component: <OAuth />,
  },
];

export default routes;
