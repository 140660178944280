/* eslint-disable no-useless-escape */
// Validates an Email Address
export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
export const validatePasswordStrength = (password) => {
  const reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return reg.test(password);
};
// Shorten a text to a limit
export const wrapSentence = (longSentence, length = 150) => {
  if (longSentence.length > length) {
    return `${longSentence.substring(0, length)} ...`;
  }
  return longSentence;
};
// This fucntion converts a string to title case
export const toTitleCase = (title) =>
  title.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
// This function converts a string to sentance case
export const toSentanceCase = (sentence) =>
  sentence.toLowerCase().replace(/(^\s*\w|[\.\!?]\s*\w)/g, (c) => c.toUpperCase());
// Returns a Human Readabale Date
export const formatDate = (date = new Date()) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return `${date.getDate()}-${months[date.getMonth()]}-${date.getFullYear()}`;
};
// Returns a Human Readabale Date
export const getLocalDateTime = (date = new Date()) =>
  `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  })}`;
// Returns a Human Readabale Date
export const getDateTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${date.toLocaleString(
    "en-US",
    { hour: "numeric", minute: "numeric", second: "numeric", hour12: true }
  )}`;
};
// App status string modifiers
export const getAppStatus = (status) => status;
export const getInitials = (word) => word.substr(0, 1).toUpperCase();
