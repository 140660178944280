import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Footer from "layouts/component/footer";

function BasicLayout({ children, showFooter }) {
  return (
    <PageLayout>
      <MDBox
        position="absolute"
        width="100%"
        minHeight="100vh"
        bgColor="linear-gradient(to bottom, #22C5DB, #191654)"
      />
      <MDBox px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
          <Grid item xs={11} sm={9} md={6} lg={4} xl={3}>
            {children}
          </Grid>
        </Grid>
      </MDBox>
      {showFooter ? <Footer light /> : null}
    </PageLayout>
  );
}

BasicLayout.defaultProps = {
  showFooter: false,
};

// Typechecking props for the BasicLayout
BasicLayout.propTypes = {
  // image: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  showFooter: PropTypes.bool,
};

export default BasicLayout;
