// @mui material components
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import BasicLayout from "layouts/component/layouts/basiclayout";
import { redirectToLogin } from "utils/constants";

function Logout() {
  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox pt={3} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox className="text-center">
              <MDTypography color="success" variant="h3">
                Logout Successful
              </MDTypography>
              <br />
              <MDTypography variant="subtitle1">
                We have logged you out of IgniteConnex Exchange.
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" onClick={() => redirectToLogin()} fullWidth>
                Back to Login
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Logout;
