import React from "react";
import Remarkable from "react-remarkable";
import "./index.css";

function Markdown(markdown) {
  const styles = {
    wrapper: {
      padding: "20px",
      margin: "auto",
      backgroundColor: "#f8fafc",
      borderRadius: "15px",
      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.2)",
      fontFamily:
        "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji",
      fontSize: "16px",
      lineHeight: "1.6",
    },
  };

  return (
    <div className="markdown-remarkable-wrapper" style={styles.wrapper}>
      <Remarkable
        source={markdown}
        options={{
          html: true,
          breaks: true,
          langPrefix: "language-",
        }}
      />
    </div>
  );
}

export default Markdown;
