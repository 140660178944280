// prop-types is library for typechecking of props
import { useState } from "react";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Button from "@mui/material/Button";
import { Tooltip } from "@material-ui/core";
import MDBox from "components/MDBox";
import { getAsync } from "utils/http-client";
import { AUX_URL, CONNECTORS, pathname } from "utils/endpoints";

// Material Dashboard 2 React components
import MDTypography from "components/MDTypography";
import { redirectToLogin, CURRENT_PAGE_URL } from "utils/constants";
import { setSessionItem } from "utils/storage";
import { wrapSentence } from "utils/strings";

function ConnectorActionCard({ title, description, authenticated }) {
  const { name } = useParams();
  const [copyToolTipText, setCopyToolTipText] = useState("Copy To Clipboard");
  const [downloadToolTipText, setDownloadToolTipText] = useState("Download");
  const resetDefaults = () => {
    setCopyToolTipText("Copy to Clipboard");
    setDownloadToolTipText("Download");
  };

  const handleButtonClick = async (action) => {
    if (!authenticated) {
      setSessionItem(CURRENT_PAGE_URL, pathname);
      redirectToLogin();
      return;
    }

    const response = await getAsync(`${AUX_URL}/${CONNECTORS}/${name}/actions/${title}?name=true`);

    if (response?.status === 200) {
      const json = JSON.stringify(response.data.action.flow_json);
      if (action === "copy") {
        navigator.clipboard.writeText(json);
        setCopyToolTipText("Copied");
      } else {
        const element = document.createElement("a");
        const file = new Blob([json], { type: "text/plain" });
        element.href = URL.createObjectURL(file);
        element.download = `${name}-${title}.json`;
        document.body.appendChild(element);
        element.click();
        setDownloadToolTipText("Downloaded");
      }
    }
  };

  const handleViewDetails = (connectorName) => {
    window.open(`/connectors/${name}/${connectorName}`, "_blank");
  };

  return (
    <MDBox
      sx={{
        boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.15)",
        minHeight: "12.5rem",
        maxHeight: "20rem",
      }}
      borderRadius="8px"
      p={2}
    >
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Grid container>
            <Grid item xs={12} md={12} lg={12}>
              <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize" mb={1}>
                {title}
              </MDTypography>
            </Grid>
            <Grid xs={12} md={12} lg={12} sx={{ minHeight: "5rem", maxHeight: "7rem" }}>
              <MDTypography variant="subtitle2" color="text" fontWeight="regular" mb={1}>
                {wrapSentence(description, 95)}
              </MDTypography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} lg={12}>
          <MDBox>
            <Tooltip title={authenticated ? copyToolTipText : "Login to Copy Code"} placement="top">
              <Button
                sx={{ borderRadius: "40px", borderColor: "#22C5DB", color: "#22C5DB", margin: 1 }}
                component="a"
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                style={{ marginLeft: "auto" }}
                onClick={() => handleButtonClick("copy")}
                onMouseLeave={() => resetDefaults()}
              >
                <MDTypography variant="body2" fontWeight="bold" sx={{ color: "#22C5DB" }}>
                  Copy to Clipboard <Icon>{authenticated ? "copy" : "lock"}</Icon>
                </MDTypography>
              </Button>
            </Tooltip>
            <Tooltip
              title={authenticated ? downloadToolTipText : "Login to Download Code"}
              placement="top"
            >
              <Button
                sx={{ borderRadius: "40px", borderColor: "#22C5DB", color: "#22C5DB", margin: 1 }}
                component="a"
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                style={{ marginLeft: "auto" }}
                onClick={() => handleButtonClick("download")}
                onMouseLeave={() => resetDefaults()}
              >
                <MDTypography variant="body2" fontWeight="bold" sx={{ color: "#22C5DB" }}>
                  Download <Icon>{authenticated ? "download" : "lock"}</Icon>
                </MDTypography>
              </Button>
            </Tooltip>
            <Tooltip title="Click to Detailed View" placement="top">
              <Button
                sx={{ borderRadius: "40px", borderColor: "#22C5DB", color: "#22C5DB", margin: 1 }}
                component="a"
                target="_blank"
                rel="noreferrer"
                variant="outlined"
                size="small"
                style={{ marginLeft: "auto" }}
                onClick={() => handleViewDetails(title)}
                onMouseLeave={() => resetDefaults()}
              >
                <MDTypography variant="body2" fontWeight="bold" sx={{ color: "#22C5DB" }}>
                  View Details <Icon>open_in_new_tab</Icon>
                </MDTypography>
              </Button>
            </Tooltip>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  );
}

// Setting default values for the props of ConnectorActionCard
ConnectorActionCard.defaultProps = {
  title: "",
  description: "",
};

// Typechecking props for the ConnectorActionCard
ConnectorActionCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  authenticated: PropTypes.bool.isRequired,
};

export default ConnectorActionCard;
