// React Elements
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";

import { PageLoader } from "utils/loader";
import { getAsync } from "utils/http-client";
import { redirectToLogin, CURRENT_PAGE_URL } from "utils/constants";

import { KEYCLOAK_SSO_TOKEN_URL } from "utils/endpoints";
import { handleOAuth, isAuthenticated } from "utils/auth";
import { getSessionItem } from "utils/storage";

// @mui material components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import PageLayout from "examples/LayoutContainers/PageLayout";
import BasicLayout from "layouts/component/layouts/basiclayout";

const errorMap = {
  100: "Unexpected Error!",
  101: "Invalid State Parameter, Please refresh your page and start again.",
  102: "An error occured while validating your identity. If you continue seeing this error please contact support@igniteconnex.com",
  103: "You're not authorized to use this application. Please contact your organization admin for assistance.",
  104: "Invalid Session, Please refresh your page and start again.",
};

function OAuth() {
  const { token } = useParams();
  const [loading, setLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(errorMap[100]);

  const redirectToHomePage = () => {
    const url = getSessionItem(CURRENT_PAGE_URL);
    if (url) {
      window.location.href = url;
    } else {
      window.location.href = "/connectors";
    }
  };

  useEffect(async () => {
    setLoading(true);
    if (token) {
      if (token === "error") {
        setErrorMessage(errorMap[100]);
        setError(true);
      } else {
        const response = await getAsync(`${KEYCLOAK_SSO_TOKEN_URL}/${token}`);
        if (response && response.data) {
          await handleOAuth(response);
          setAuthenticated(isAuthenticated());
        } else {
          setError(true);
        }
      }
      setLoading(false);
    } else {
      setRedirect(true);
      setLoading(false);
    }
  }, []);

  if (authenticated) {
    redirectToHomePage();
  }
  if (redirect) {
    redirectToLogin();
  }
  return (
    <PageLayout>
      {loading ? <PageLoader /> : null}
      {error ? (
        <BasicLayout image={bgImage}>
          <Card>
            <MDBox pt={3} pb={3} px={3}>
              <MDBox component="form" role="form">
                <MDBox className="text-center">
                  <MDTypography color="error" variant="h3">
                    SSO Login Failed
                  </MDTypography>
                  <br />
                  <MDTypography variant="subtitle1">
                    We could not log this user into IgniteConnex Exchange. Please try again.
                  </MDTypography>
                  <MDTypography variant="subtitle2">{errorMessage}</MDTypography>
                </MDBox>
                <MDBox mt={4} mb={1}>
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={() => redirectToLogin()}
                    fullWidth
                  >
                    Back to Login
                  </MDButton>
                </MDBox>
              </MDBox>
            </MDBox>
          </Card>
        </BasicLayout>
      ) : null}
    </PageLayout>
  );
}

export default OAuth;
