/**
=========================================================
* Material Dashboard 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import { v4 } from "uuid";
import Card from "@mui/material/Card";
import Link from "@mui/material/Link";
import React, { useState } from "react";
import { Grid, IconButton, useMediaQuery, Collapse, Button } from "@mui/material";

// Material Dashboard 2 React components\
import MenuIcon from "@mui/icons-material/Menu";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import mobileLogo from "assets/igcnx/igcnx-color-tall.svg";
// import igniteConnexLogo from "assets/igcnx/igcnx-color-long.svg";

function NavBar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const isBreakpointMatch = useMediaQuery("(max-width: 865px) ");

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen);
  };
  const links = [
    { href: "https://www.igniteconnex.com/", name: "igniteconnex.com" },
    { href: "https://docs.igniteconnex.io/", name: "Documentation" },
  ];

  const handleLogoClick = () => {
    window.location.href = "/connectors";
  };

  const renderLinks = () =>
    links.map((link) => (
      <span key={v4()}>
        <MDBox key={link.name} px={1}>
          <Link href={link.href} target="_blank">
            <MDTypography
              variant="button"
              fontWeight="bold"
              fontSize={isBreakpointMatch ? "15px" : "16px"}
              fontFamily="roboto"
              style={{ color: "#667085" }}
            >
              {link.name}
            </MDTypography>
          </Link>
        </MDBox>
      </span>
    ));

  return (
    <Card
      xs={4}
      sm={12}
      lg={12}
      md={12}
      sx={{
        zIndex: 1000,
        position: "fixed",
        width: "100%",
        borderRadius: 0,
      }}
    >
      <Grid container m={1} display="flex" justifyContent="space-between">
        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <MDBox
            ml={1}
            display="flex"
            justifyContent="left"
            sx={{
              cursor: "pointer",
            }}
            component="img"
            src={
              isBreakpointMatch
                ? "https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-color-tall-black.svg"
                : "https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-color-long-black.svg"
            }
            width="fit-content"
            style={{
              maxWidth: "13rem", // Adjust this value as needed
              maxHeight: "3rem",
            }}
            alt="Brand"
            onClick={handleLogoClick}
          />
        </Grid>
        <Grid item xs={9} sm={9} md={9} lg={9} xl={9}>
          <MDBox display="flex" justifyContent="right">
            {isBreakpointMatch ? (
              <>
                <MDBox marginRight="2rem">
                  <Button
                    variant="contained"
                    color="primary"
                    href="https://www.igniteconnex.com/contact-us"
                    target="_blank"
                    sx={{
                      backgroundColor: "#22c0d7",
                      borderRadius: "40px",
                      marginRight: "0.5rem",
                    }}
                  >
                    <MDTypography
                      variant="button"
                      fontWeight="bold"
                      fontSize="15px"
                      fontFamily="roboto"
                      style={{ color: "black" }}
                    >
                      Schedule a Demo
                    </MDTypography>
                  </Button>
                  <IconButton color="inherit" aria-label="menu" onClick={handleMenuClick}>
                    <MenuIcon />
                  </IconButton>
                  <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                    <MDBox ml={2} xs={6} sm={12} md={12}>
                      {renderLinks()}
                    </MDBox>
                  </Collapse>
                </MDBox>
              </>
            ) : (
              <MDBox display="flex" marginRight="80px">
                {renderLinks()}
                <Button
                  variant="contained"
                  color="primary"
                  href="https://www.igniteconnex.com/contact-us"
                  target="_blank"
                  sx={{
                    backgroundColor: "#22c0d7",
                    borderRadius: "40px",
                    marginLeft: "1rem",
                  }}
                >
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    fontSize="16px"
                    fontFamily="roboto"
                    style={{ color: "black" }}
                  >
                    Schedule a Demo
                  </MDTypography>
                </Button>
              </MDBox>
            )}
          </MDBox>
        </Grid>
      </Grid>
    </Card>
  );
}

export default NavBar;
