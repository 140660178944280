// Texts
export const EMPTY_TEXT = `—————`;
export const LOADING_TEXT = `Loading...`;

// Keys
export const USER_THEME_PREFERENCE = `user_theme_preference`;
export const ORG_NAME = `org_name`;
export const ORG_BASE_URL = `org_base_url`;
export const ORG_INFO = `org_info`;
export const DASHBOARD_SETTING = `dashboard`;
export const EXCHANGE_ACCESS_TOKEN = `exchange_access_token`;
export const EXCHANGE_ACCESS_TOKEN_EXPIRES_IN = `exchange_access_token_expires_in`;
export const EXCHANGE_REFRESH_TOKEN = `exchange_refresh_token`;
export const EXCHANGE_REFRESH_TOKEN_EXPIRES_IN = `exchange_refresh_token_expires_in`;
export const EXCHANGE_ACCESS_TOKEN_PUBLIC_KEY = `exchange_access_token_public_key`;
export const USER_ROLES = `user_roles`;
export const SSO_LOGOUT_URL = `sso_logout_url`;
export const CURRENT_PAGE_URL = `current_page_url`;

// HTTP Response Categories
export const HTTP_SUCCESS = [200, 201, 202, 204];
export const HTTP_REDIRECT = [300, 301, 302, 303, 307];
export const HTTP_CLIENT_ERROR = [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 415];
export const HTTP_SERVER_ERROR = [500, 501, 502, 503, 504, 505];

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(`${text}`);
};

export const getRandomState = () =>
  `${Math.random().toString(36).substr(2, 10)}${Math.random()
    .toString(36)
    .substr(2, 10)}${Math.random().toString(36).substr(2, 10)}`;

export const redirectToLogin = () => {
  window.location.href = "/login";
};

export const markdownOptions = {
  html: true,
  breaks: true,
  langPrefix: "language-",
};
